<!--
 * @Author: your name
 * @Date: 2021-08-13 17:04:33
 * @LastEditTime: 2021-08-16 12:06:26
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \hl_admin\src\components\Upload.vue
-->
<template>
  <div :id="id"
       v-loading="loading"
       class="upload">
    <slot>
      <div class="logo"
           v-if="value">
        <img :src="value"
             alt="img"
             mode="widthFix"
             width="100%"
             height="auto">
      </div>
      <div class="upload-box flex-center"
           v-else>
        <i class="el-icon-plus"></i>
        <span class="text">点击上传</span>
      </div>
    </slot>
  </div>
</template>

<script>
import plupload from 'plupload'
import * as commonApi from '../api/commonapi'

export default {
  name: 'Upload',
  props: {
    id: { default: 'upload-1' },
    src: { default: 'banner' },
    tips: { default: '' },
    oss: Object,
    value: String
  },
  data () {
    return {
      loading: false,
      config: null,
    }
  },
  created () {
    this.config = this.oss
  },
  mounted () {
    if (!this.config)
      this.getConfig()
    else {
      this.initUpload()
    }
  },
  methods: {
    getConfig () {
      commonApi.getOSSToken({ dir: this.src }).then(({ data: { data } }) => {
        if (data) {
          if (data.host.indexOf('http' === -1)) data.host = 'https://' + data.host
          this.config = data
          this.initUpload()
        }
      })
    },
    async initUpload () {
      const { host, dir, policy, accessid, signature } = this.config
      let fileTimestamp = ''
      const that = this
      const pluploader = new plupload.Uploader({
        runtimes: 'html5, flash, silverlight, html4',
        browse_button: this.id,
        multi_selection: false,
        flash_swf_url: '/plupload/js/Moxie.swf',
        silverlight_xap_url: '/plupload/js/Moxie.xap',
        url: host,
        init: {
          FilesAdded: (up, files) => {
            this.loading = true
            const suffix = files[0].name.split('.').pop()
            fileTimestamp = this.setTimestamp()
            const newFilePath = `${fileTimestamp}.${suffix}`
            pluploader.setOption({
              multipart_params: {
                key: dir + '/' + newFilePath,
                policy: policy,
                OSSAccessKeyId: accessid,
                success_action_status: '200',
                signature: signature
              }
            })
            pluploader.start()
          },
          FileUploaded: (up, file, info) => {
            this.loading = false
            const suffix = file.name.split('.').pop()
            const newFilePath = `${fileTimestamp}.${suffix}`
            if (info.status === 200) {
              const imgurl = `${host}/${dir}/${newFilePath}`
              that.$emit('input', imgurl)
              //   that.$emit('success', imgurl)
            } else {
              this.loading = false
            }
          }
        }
      })
      pluploader.init()
    },
    setTimestamp () {
      return this.$dayjs().valueOf()
    }
  }
}
</script>

<style scoped lang="scss">
.upload {
  display: flex;
  flex-direction: column;
  line-height: 1;
  &-box {
    width: 375px;
    height: 270px;
    flex-direction: column;
    border-radius: 4px;
    border: 1px dashed rgba(0, 0, 0, 0.15);
    cursor: pointer;
    .text {
      padding-top: 8px;
      color: rgba(0, 0, 0, 0.65);
    }
  }
  .logo {
    position: relative;
    width: 375px;
    // height: 270px;
    overflow: hidden;
    cursor: pointer;
  }
}
</style>
