<!--
 * @Author: your name
 * @Date: 2021-08-13 16:26:48
 * @LastEditTime: 2021-10-08 14:05:47
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \hl_admin\src\views\banner\views\list.vue
-->
<template>
  <div>
    <page-header title="首页Banner部署"></page-header>
    <div class="container">
      <el-form :model="search"
               inline
               class="dtb-search-form">
        <el-form-item label="标题：">
          <el-input v-model="search.name"
                    placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary"
                     @click="getList">查询</el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <el-button type="primary"
                 class="add"
                 @click="onAddBanner">添加Banner</el-button>
      <el-table :data="list"
                class="dtb-show-table">
        <el-table-column label="ID"
                         prop="id"></el-table-column>
        <el-table-column label="活动名称"
                         prop="banner_name"></el-table-column>
        <el-table-column label="状态"
                         prop="status">
          <template slot-scope="scope">
            {{scope.row.status === 1 ? '有效' : '失效'}}
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button @click="edit(scope.row)"
                       type="text"
                       size="small">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="dtb-table-pagination">
        <span>共{{ totalCount }}条记录</span>
        <el-pagination background
                       layout="prev, pager, next"
                       :current-page.sync="search.page_no"
                       :page-size="search.page_size"
                       :total="totalCount"></el-pagination>
      </div>
      <el-dialog :title="selItem.id ? '编辑Banner': '添加Banner'"
                 :visible.sync="isEditDlg">
        <div class="banner-dlg">
          <el-form label-width="100px"
                   class="banner-form">
            <el-form-item label="活动名称：">
              <el-input v-model="selItem.banner_name"
                        placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="添加Banner：">
              <upload v-model="selItem.banner_url"></upload>
              <span class="tips">建议图片尺寸1125x630像素，且小于200KB，支持jpg、png</span>
            </el-form-item>
            <el-form-item label="跳转类型：">
              <el-select v-model="selItem.jump_type"
                         placeholder="请选择">
                <el-option label="不跳转"
                           :value="0">不跳转</el-option>
                <el-option label="H5"
                           :value="1">H5</el-option>
                <el-option label="小程序页面"
                           :value="2">小程序页面</el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="跳转参数："
                          v-if="selItem.jump_type !== 0">
              <el-input v-model="selItem.jump_params"></el-input>
            </el-form-item>
            <el-form-item label="活动状态：">
              <el-select v-model="selItem.status"
                         placeholder="请选择">
                <el-option label="生效"
                           :value="1">生效</el-option>
                <el-option label="失效"
                           :value="0">失效</el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="example"
               v-show="false">
            <div class="title">Banner示例</div>
            <img src="https://tbresource.oss-cn-hangzhou.aliyuncs.com/hl/example/example-banner.png"
                 class="example-banner" />
          </div>
        </div>
        <div class="footer"
             slot="footer">
          <el-button type="primary"
                     @click="submit">确定</el-button>
          <el-button @click="isEditDlg = false">取消</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import PageHeader from '../../common/components/PageHeader.vue'
import * as api from '../api/banner'
import Upload from '../../../components/Upload.vue'
export default {
  components: { PageHeader, Upload },
  data () {
    return {
      search: { name: '', page_no: 1, page_size: 10 },
      list: [],
      totalCount: 0,
      isEditDlg: false,
      selItem: {}
    }
  },
  mounted () {
    this.getList()
  },
  watch: {
    'search.page_no' () {
      this.getList()
    }
  },
  methods: {
    onAddBanner () {
      this.isEditDlg = true
      this.selItem = { banner_name: '', banner_url: '', jump_type: 0, jump_params: '', status: 1 }
    },
    submit () {
      api.edit(this.selItem).then(({ code }) => {
        if (code === 200) {
          this.isEditDlg = false
          this.page_no = 1
          this.getList()
        }
      })
    },
    getList () {
      api.getList({ page_no: this.search.page_no - 1, page_size: this.search.page_size, name: this.search.name })
        .then(({ data: { code, data } }) => {
          if (code === 200) {
            this.list = data.list
            this.totalCount = data.total_count
          }
        })
    },
    reset () {
      this.search.name = ''
      if (this.search.page_no === 1) {
        this.getList()
      } else {
        this.search.page_no = 1
      }
    },
    add () {
      this.edit({ banner_name: '', banner_url: '', jump_type: 0, jump_params: '', status: 1 })
    },
    edit (row) {
      this.selItem = row
      this.isEditDlg = true
    }
  }

}
</script>

<style lang="scss" scoped>
.container {
  flex: 1;
  background: white;
  margin: 18px;
  padding: 18px;
  .add {
    margin: 24px;
    margin-top: 0;
    display: block;
  }
}

.banner-dlg {
  display: flex;
  background: white;
  padding: 20px;
  .banner-form {
    flex: 2 0 0;
    padding-right: 40px;
    // border-right: 1px solid #e8e8e8;
  }
  .example {
    flex: 1.5 0 0;
    padding-left: 40px;
    &-banner {
      width: 90.53%;
      margin-top: 16px;
    }
    .title {
      font-size: 16px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
    }
  }
  .tips {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45);
  }
}
</style>

<style lang="scss">
.el-dialog {
  &__body {
    background: #f0f2f5;
    padding: 20px;
  }
}
</style>