/*
 * @Author: your name
 * @Date: 2021-08-13 16:25:36
 * @LastEditTime: 2021-08-13 16:26:38
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \hl_admin\src\views\banner\api\banner.js
 */
import { get, post } from "../../../api/requests";

export const getList = data => {
  return get("banner/list", data);
};

export const edit = data => {
  return post("banner/edit", data);
};
